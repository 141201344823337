import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from 'react-router-dom';
import './App.css';
import AdminOnboardingPanel from './components/AdminOnboarding/AdminOnboardingPanel';
import OfficeSchedulingPanel from './components/OfficeScheduling/OfficeSchedulingPanel';
import AdminProviders from './components/AdminProviders/AdminProviders';
import ClaimsManagementPage from './components/ClaimsManagement/ClaimsManagementPage';
import DataCorrections from './components/DataCorrections/DataCorrections';
import Facilities from './components/Facilities/Facilities';
import HiePage from './components/HIE/HiePage';
import MemberDetailEditPage from './components/Members/MemberDetail/MemberDetailEditPage';
import MembersList from './components/Members/MembersList';
import ReportsPage from './components/Reports/ReportsPage';
import Requests from './components/Requests/Requests';
import AdminMembers from './components/AdminMembers/AdminMembers';
import AdminRegions from './components/AdminRegions/AdminRegions';
import AdminRoles from './components/AdminRoles/AdminRoles';
import AdminHealthPlan from './components/AdminHealthPlan/AdminHealthPlan';
import AdminTeams from './components/AdminTeams/AdminTeams';
import ClinicalData from './components/ClinicalData/ClinicalData';
import AdminNavbar from './components/Navigation/AdminNavbar/AdminNavbar';
import SmartDashboard from './components/SmartDashboard/SmartDashboard';
import MemberEducation from './components/MemberEducation/MemberEducation';
import IntegratedCarePlan from './components/IntegratedCarePlan/IntegratedCarePlan';
import MemberDetailHIE from './components/MemberDetailHIE/MemberDetailHIE';
import MemberDocuments from './components/MemberDocuments/MemberDocuments';
import Login from './components/Login/Login';
import MySchedulesCalendar from './components/Calendar/MySchedulesCalendar/MySchedulesCalendar';
import ProviderNavbar from './components/Navigation/ProviderNavbar/ProviderNavbar';
import MemberDemographicsDetails from './components/MemberChartDetails/MemberDemographicsDetails';
import OnboardingWorkflow from './components/OnboardingWorkflow/OnboardingWorkflow';
import MemberEligibilityReport from './components/Reports/ReportPages/MemberEligibilityReport';
import MemberEncounterBillingReport from './components/Reports/ReportPages/MemberEncounterBillingReport';
import MemberStatusReport from './components/Reports/ReportPages/MemberStatusReport';
import MemberVitalSignsReport from './components/Reports/ReportPages/MemberVitalSignsReport';
import OnboardingMemberReport from './components/Reports/ReportPages/OnboardingMemberReport';
import RequestsReport from './components/Reports/ReportPages/Requests/RequestsReport';
import EngagementsVsEncountersReport from './components/Reports/ReportPages/EngagementsVsEncountersReport';
import EncounterBillingReportHistory from './components/Reports/ReportPages/EncounterBillingReportHistory';
import GenerateEncounterBillingReport from './components/Reports/ReportPages/GenerateEncounterBillingReport';
import MemberBirthdayReport from './components/Reports/ReportPages/MemberBirthdayReport';
import MemberDetailsReport from './components/Reports/ReportPages/MemberDetailsReport';
import SDOHReports from './components/Reports/ReportPages/SDOHAssessmentsReports';
import TransitionalCareReport from './components/Reports/ReportPages/TransitionalCareReport';
import TeamReport from './components/Reports/ReportPages/TeamReport';
import SurveyReport from './components/Reports/ReportPages/SurveyReport';
import MetricSummary from './components/SmartDashboard/MetricSummary/MetricSummary';
import MetricGoals from './components/MetricGoal/MetricGoal';
import SchedulesTabsSelector from './components/Calendar/SchedulesTabsSelector';
import MedicationReconciliation from './components/Assessments/MedicationReconciliation/MedicationReconciliation';
import DocumentEngagements from './components/DocumentEngagements/DocumentEngagements';
import DocumentOnboardings from './components/DocumentOnboardings/DocumentOnboardings';
import Timelines from './components/Timelines/Timelines';
import TransitionalCare from './components/TransitionalCare/TransitionalCare';
import PerformanceDashboard from './components/PerformanceDashboard/PerformanceDashboard';
import Assessment from './components/Assessments/Assessment';
import DocumentTrackingSummaryDetail from './components/Widgets/DocumentTrackingSummary/DocumentTrackingSummaryDetail';
import EngagementSummaryDetail from './components/Widgets/EngagementSummary/EngagementSummaryDetail';
import TeamInPersonVisitsSummaryDetail from './components/Widgets/EngagementSummary/TeamInPersonVisitsSummaryDetail';
import FuhFumClosedSummaryDetail from './components/Widgets/FuhFumSummary/FuhFumClosed/FuhFumClosedSummaryDetail';
import FuhFumExceptionSummaryDetail from './components/Widgets/FuhFumSummary/FuhFumException/FuhFumExceptionSummaryDetail';
import FuhFumInProgressSummaryDetail from './components/Widgets/FuhFumSummary/FuhFumInProgress/FuhFumInProgressSummaryDetail';
import FuhFumUndeterminedSummaryDetail from './components/Widgets/FuhFumSummary/FuhFumUndetermined/FuhFumUndeterminedSummaryDetail';
import FuhFumQualityMetricsDetail from './components/Widgets/FuhFumSummary/FuhFumQualityMetrics/FuhFumQualityMetricsDetail';
import MembersWithoutEncounterDetail from './components/Widgets/MembersWithoutEncounter/MembersWithoutEncounterDetail';
import SchedulingSummaryDetail from './components/Widgets/SchedulingSummary/SchedulingSummaryDetail';
import SocialWorkerRequestSummaryDetail from './components/Widgets/SocialWorkerRequestSummary/SocialWorkerRequestSummaryDetail';
import TeamLevelTcSummaryDetail from './components/Widgets/TeamLevelTcSummary/TeamLevelTcSummaryDetail';
import TeamMemberSummaryDetail from './components/Widgets/TeamMemberSummary/TeamMemberSummaryDetail';
import AcCostDetail from './components/SmartDashboard/Analytics/ACCost/ACCostDetail';
import FfCostDetail from './components/SmartDashboard/Analytics/FFCost/FFCostDetail';
import BhRtcCostDetail from './components/SmartDashboard/Analytics/BhRtcCost/BhRtcCostDetail';
import EdVisitDetail from './components/SmartDashboard/Analytics/EDVisit/EDVisitDetail';
import PhCostDetail from './components/SmartDashboard/Analytics/PHCost/PHCostDetail';
import TotalCostDetail from './components/SmartDashboard/Analytics/TotalCost/TotalCostDetail';
import HedisMemberDetail from './components/HedisMetrics/HedisMemberDetail';
import FuhFum from './components/FuhFum/FuhFum';
import ChangePassword from './components/Navigation/ProviderNavbar/ChangePassword/ChangePassword';
import BasicSettings from './components/Navigation/ProviderNavbar/BasicSettings/BasicSettings';
import ProviderLeave from './components/ProviderLeave/ProviderLeave';
import AuthRequired from './components/Navigation/AuthRequired/AuthRequired';
import MemberChartScheduler from './components/Calendar/MemberChartCalendar/MemberChartScheduler';
import BudgetForm from './components/Assessments/BudgetForm/BudgetForm';
import NotificationsPage from './components/NotificationsPage/NotificationsPage';
import MemberVitalSigns from './components/MemberVitalSigns/MemberVitalSigns';
import { Error } from './components/Navigation/Error/Error';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import IndividualInPersonVisitsSummaryDetail from './components/Widgets/EngagementSummary/IndividualInPersonVisitsSummaryDetail';
import ICD10 from './components/ICD10/ICD10';
import UpcomingAppointments from './components/Appointments/UpcomingAppointments';
import MessageDisplay from './components/Shared/MessageDisplay';
import OnboardCallPerformancesDetail from './components/SmartDashboard/DashboardPages/OnboardCallPerformance/OnboardCallPerformanceDetail';

const queryClient = new QueryClient();

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" errorElement={<Error title="CHP - Error" />}>
            <Route index element={<Login title="CHP - Login" />} />
            {/* Start Admin */}
            <Route element={<AuthRequired />} errorElement={<Error />}>
                <Route path="admin" element={<AdminNavbar />}>
                    <Route path="providers" element={<AdminProviders title="CHP - Providers" />} />
                    <Route path="members" element={<AdminMembers title="CHP - Members" />} />
                    <Route path="onboarding" element={<AdminOnboardingPanel title="CHP - Onboarding" />} />
                    <Route path="roles" element={<AdminRoles title="CHP - Roles" />} />
                    <Route path="teams" element={<AdminTeams title="CHP - Teams" />} />
                    <Route path="regions" element={<AdminRegions title="CHP - Regions" />} />
                    <Route path="facilities" element={<Facilities title="CHP - Facilities" />} />
                    <Route path="healthplan" element={<AdminHealthPlan title="CHP - Health Plan" />} />
                    <Route path="metricgoals" element={<MetricGoals title="CHP - Metric Goals" />} />

                    <Route path="datacorrections" element={<Outlet />}>
                        <Route index element={<DataCorrections title="CHP - Data Corrections" />} />
                        <Route path="icd10" element={<ICD10 />} />
                    </Route>
                </Route>
                {/* End Admin */}

                {/* Start Providers */}
                <Route path="provider" element={<ProviderNavbar />}>
                    <Route path="upcomingappointments" element={<UpcomingAppointments />} />
                    <Route path="changeproviderpassword" element={<ChangePassword />} />
                    <Route path="basicsettings" element={<BasicSettings />} />
                    <Route path="providerleave" element={<ProviderLeave />} />
                    <Route path="notifications" element={<NotificationsPage />} />
                    <Route path="metricsummary" element={<Outlet />}>
                        <Route index element={<MetricSummary title="CHP - Metric Summary" />} />
                        <Route path="smart-dashboard" element={<SmartDashboard title="CHP - Smart Dashboard" />} />
                        <Route path="accostdetail" element={<AcCostDetail />} />
                        <Route path="appointmentmemberdetail" element={<DocumentTrackingSummaryDetail />} />
                        <Route path="bhrtccostdetail" element={<BhRtcCostDetail />} />
                        <Route path="edvisitdetail" element={<EdVisitDetail />} />
                        <Route path="ffcostdetail" element={<FfCostDetail />} />
                        <Route path="fuhfumclosedmemberdetail" element={<FuhFumClosedSummaryDetail />} />
                        <Route path="fuhfumexceptionmemberdetail" element={<FuhFumExceptionSummaryDetail />} />
                        <Route path="fuhfuminprogressmemberdetail" element={<FuhFumInProgressSummaryDetail />} />
                        <Route path="fuhfumqualitymetricsdetail" element={<FuhFumQualityMetricsDetail />} />
                        <Route path="fuhfumundeterminedmemberdetail" element={<FuhFumUndeterminedSummaryDetail />} />
                        <Route path="engagementsummarydetail" element={<EngagementSummaryDetail />} />
                        <Route path="engagementmemberdetail" element={<TeamInPersonVisitsSummaryDetail />} />
                        <Route path="individualinpersonvisitsdetail" element={<IndividualInPersonVisitsSummaryDetail />} />
                        <Route path="hedismemberdetail/:type" element={<HedisMemberDetail />} />
                        <Route path="memberswithoutencounterdetail" element={<MembersWithoutEncounterDetail />} />
                        <Route path="phcostdetail" element={<PhCostDetail />} />
                        <Route path="schedulingsummarydetail" element={<SchedulingSummaryDetail />} />
                        <Route path="socialworkerrequestsummarydetail" element={<SocialWorkerRequestSummaryDetail />} />
                        <Route path="teammemberdetail" element={<TeamMemberSummaryDetail />} />
                        <Route path="totalcostdetail" element={<TotalCostDetail />} />
                        <Route path="teamleveltcsummarydetail" element={<TeamLevelTcSummaryDetail />} />
                        <Route path="onboardcallperformancedetail" element={<OnboardCallPerformancesDetail />} />
                    </Route>

                    <Route path="members-list" element={<Outlet />}>
                        <Route index element={<MembersList title="CHP - Members List" />} />
                        <Route path="member-chart/:memberId" element={<MemberDemographicsDetails title="CHP - Member Chart" />}>
                            <Route path="member-profile" element={<MemberDetailEditPage />} />
                            <Route path="assessments" element={<Outlet />}>
                                <Route index element={<Assessment />} />
                            </Route>
                            <Route path="budget-form" element={<BudgetForm isOtherAssessment={true} />} />
                            <Route path="clinical-data" element={<ClinicalData />} />
                            <Route path="documents" element={<MemberDocuments />} />
                            <Route path="education" element={<MemberEducation />} />
                            <Route path="document-engagement" element={<DocumentEngagements />} />
                            <Route path="onboarding-engagement" element={<DocumentOnboardings />} />
                            <Route path="hie" element={<MemberDetailHIE />} />

                            <Route path="integrated-care-plan" element={<IntegratedCarePlan />} />
                            <Route path="medication-management" element={<MedicationReconciliation memberDetail={true} />} />
                            <Route path="performance" element={<PerformanceDashboard />} />
                            <Route path="member-requests" element={<Requests title="CHP - Member Chart" />} />
                            <Route path="scheduling" element={<MemberChartScheduler />} />
                            <Route path="timeline" element={<Timelines />} />
                            <Route path="transitional-care" element={<TransitionalCare isAssessment={false} />} />
                            <Route path="vital-signs" element={<MemberVitalSigns />} />
                        </Route>
                    </Route>

                    <Route path="requests" element={<Requests title="CHP - Requests" />} />
                    <Route path="onboarding-workflow" element={<OnboardingWorkflow title="CHP - Onboarding Workflow" />} />
                    <Route path="myschedule" element={<MySchedulesCalendar title="CHP - My Schedule" />} />
                    <Route path="schedules" element={<SchedulesTabsSelector title="CHP - Schedules" />} />
                    <Route path="reports" element={<ReportsPage title="CHP - Reports" />}>
                        <Route path="engagements-vs-encounters" element={<EngagementsVsEncountersReport />} />
                        <Route path="encounter-billing-report-history" element={<EncounterBillingReportHistory />} />
                        <Route path="generate-encounter-billing-report" element={<GenerateEncounterBillingReport />} />
                        <Route path="member-birthday-report" element={<MemberBirthdayReport />} />
                        <Route path="member-details-report" element={<MemberDetailsReport />} />
                        <Route path="member-eligibility-report" element={<MemberEligibilityReport />} />
                        <Route path="member-encounter-billing-report" element={<MemberEncounterBillingReport />} />
                        <Route path="member-status-report" element={<MemberStatusReport />} />
                        <Route path="member-vital-signs-report" element={<MemberVitalSignsReport />} />
                        <Route path="onboarding-member-report" element={<OnboardingMemberReport />} />
                        <Route path="requests-report" element={<RequestsReport />} />
                        <Route path="sdoh-assessment-request-report" element={<SDOHReports />} />
                        <Route path="survey-report" element={<SurveyReport />} />
                        <Route path="team-report" element={<TeamReport />} />
                        <Route path="transitional-care-report" element={<TransitionalCareReport />} />
                    </Route>
                    <Route path="hie" element={<HiePage title="CHP - HIE" />} />
                    <Route path="hie/fuhfum" element={<FuhFum />} />
                    <Route path="officescheduling" element={<OfficeSchedulingPanel title="CHP - Office Scheduling" />} />
                    <Route path="claims-management" element={<ClaimsManagementPage title="CHP - Claims Management" />} />
                </Route>
            </Route>
            {/* End Providers */}
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="update-password" element={<ChangePassword />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            {/* <Route path="*" element={<Home />} /> */}
        </Route>
    )
);

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <MessageDisplay />
            <RouterProvider router={router} />
        </QueryClientProvider>
    );
}

// function Home() {
//     return <h2>Home</h2>;
// }

export default App;
