import { Grid, Stack, Typography } from '@mui/material';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import moment from 'moment';
import { CallListTabs } from '../../../store/onboardingWorkflow.slice';

const CallItemInfo = ({ callItem, confirmationCallItem, selectedCallListTab }) => {
    const item = selectedCallListTab === CallListTabs.Confirmations ? confirmationCallItem : callItem;

    const DOB = moment(new Date(item?.DateOfBirth)).format('MM-DD-YYYY');

    const address = `${item.Address ? item.Address + ',' : ''} ${item.Address2 ? item.Address2 + ',' : ''} ${item.City ? item.City + ',' : ''} ${
        item.State ? item.State + ',' : ''
    } ${item.Zip ? item.Zip : ''}`;

    return (
        <>
            <Grid container spacing={1}>
                <Grid p={1} item xs={4} borderRight={1} borderColor={'gray'}>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} borderBottom={1}>
                        <Typography variant="body2">AHCCCS ID:</Typography>
                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                            {item?.AHCCCSID || ''}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                        <Typography variant="body2">Last Name:</Typography>
                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                            {item?.LastName || ''}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                        <Typography variant="body2">First Name:</Typography>
                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                            {item?.FirstName || ''}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid p={1} item xs={4} borderRight={1} borderColor={'gray'}>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} borderBottom={1}>
                        <Typography variant="body2">DOB:</Typography>
                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                            {`${DOB || ''} ${' (Age: ' + moment().diff(DOB, 'years') + ')' || ''}`}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                        <Typography variant="body2" marginRight={'auto'}>
                            Main Address:
                        </Typography>
                        <Typography fontWeight={700} textAlign={'right'} variant="body2">
                            {address || ''}
                        </Typography>
                    </Stack>
                    {item.IsDifferMeetingAddress && (
                        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                            <Typography variant="body2" marginRight={'auto'} textAlign={'left'}>
                                Different Meeting Address:
                            </Typography>
                            <Typography fontWeight={700} variant="body2" textAlign={'right'}>
                                {item.MeetingAddress?.FullAddress?.toUpperCase() || ''}
                            </Typography>
                        </Stack>
                    )}

                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                        <Typography variant="body2" marginRight={'auto'}>
                            Eligibility:
                        </Typography>
                        <Typography fontWeight={700} variant="body2">
                            {item?.Eligibility || ''}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid p={1} item xs={4} borderRight={1} borderColor={'gray'}>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} borderBottom={1}>
                        <Typography variant="body2" marginRight={'auto'}>
                            Status:
                        </Typography>
                        <Typography fontWeight={700} variant="body2">
                            {MemberStatusEnum[item?.MemberStatus] || ''}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                        <Typography variant="body2" marginRight={'auto'}>
                            Health Plan:
                        </Typography>
                        <Typography fontWeight={700} variant="body2" textAlign={'right'}>
                            {item?.HealthPlan || ''}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                        <Typography variant="body2" marginRight={'auto'}>
                            Population Name:
                        </Typography>
                        <Typography fontWeight={700} variant="body2" textAlign={'right'}>
                            {item?.PopulationName || ''}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};
export default CallItemInfo;
