import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { apiString as API_URL } from '../../../../utils/constants';
import { dateFormatter } from '../../../../utils/timeFormat';
import WidgetLoading from '../../Shared/WidgetLoading';
import { isDefaultDate } from '../../../../utils/common';
import Authorization from '../../../../utils/Authorization';
import { RootState } from '../../../../reducers';
import { useSelector } from 'react-redux';
import { parentUrl } from '../../../../utils/constants';
import { MenuProps } from '../../../Widgets/WidgetService';
import { TimeFrameEnum } from '../../../../Enum/TimeFrameEnum';

const appointmentTypeList = [
    { value: '1', text: 'On Board' },
    { value: '3', text: 'Follow Up' },
    { value: '27', text: 'HRA' },
    { value: '30', text: 'FUHFUM' },
    { value: '2', text: 'Initial' },
    { value: '20', text: 'Behavioral Health' },
];

const timePeriods = [
    { value: TimeFrameEnum.From1To7Days, label: TimeFrameEnum.From1To7DaysDescription },
    { value: TimeFrameEnum.From8To14Days, label: TimeFrameEnum.From8To14DaysDescription },
    { value: TimeFrameEnum.From15To30Days, label: TimeFrameEnum.From15To30DaysDescription },
    { value: TimeFrameEnum.From31To60Days, label: TimeFrameEnum.From31To60DaysDescription },
];

const ReschedulingOpportunities = (props) => {
    const allText = 'All';
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const { teams } = useSelector((state: RootState) => state.teams);
    const [selectedProvider, setSelectedProvider] = useState(Boolean(authData.UserId) ? authData.UserId : allText);
    const [selectedTeam, setSelectedTeam] = useState(allText);
    const [timeFilter, setTimeFilter] = useState(TimeFrameEnum.From1To7Days);
    const [reschedulingOpportunities, setReschedulingOpportunities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isTypeUpdated, setIsTypeUpdated] = useState(false);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const [appointmentTypes, setAppointmentTypes] = useState(appointmentTypeList.map((a) => a.text));
    const isSelectAll = appointmentTypes?.length === appointmentTypeList.length;
    const filteredTeams = teams.filter((t) => t.IsActive);

    const changeFilter = async () => {
        setIsLoading(true);
        const response = await axios.get(
            `${API_URL}/widget/getreschedulingopportunities?providerId=${selectedProvider}&teamId=${selectedTeam}&appointmentTypes=${appointmentTypes}&timeFilter=${timeFilter}&includeInActiveProviders=${showInActiveProviders}`
        );
        setReschedulingOpportunities(response.data);
        setIsLoading(false);
        setIsTypeUpdated(false);
    };

    useEffect(() => {
        changeFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProvider, selectedTeam, timeFilter, showInActiveProviders]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider(allText);
    };

    const handleClick = (event) => {
        setShow(!show);
    };

    const handleOnChange = (e: SelectChangeEvent<unknown>) => {
        let selectedValues = e.target.value as string[];
        if (selectedValues.includes(allText) && !isSelectAll) {
            selectedValues = appointmentTypeList.map((o) => o.text);
        } else if (selectedValues.includes(allText)) {
            selectedValues = [];
        }

        setSelectedAppointmentTypeIds(selectedValues);
    };

    const setSelectedAppointmentTypeIds = (selectedIds: string[]) => {
        selectedIds = selectedIds.length > 1 ? selectedIds.filter((s) => s !== allText) : selectedIds;
        setAppointmentTypes(selectedIds);
        setIsTypeUpdated(true);
    };

    const columns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS ID',
            minWidth: 120,
        },
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 180,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 100,
            renderCell: (params) => {
                let contactDate = isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB));
                return <>{contactDate}</>;
            },
        },
        {
            field: 'DateInfo',
            headerName: 'Appointment Date',
            minWidth: 170,
        },
        {
            field: 'AppointmentType',
            headerName: 'AppointmentType',
            minWidth: 150,
        },
        {
            field: 'AppointmentStatus',
            headerName: 'AppointmentStatus',
            minWidth: 160,
        },
    ];

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                            Rescheduling Opportunities
                        </Typography>
                        <Typography display={'inline-block'} variant="body1" color={'primary'}>
                            &#40;{reschedulingOpportunities.length}&#41;
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Displays all cancelled and NCNS appointments that have not been rescheduled within the selected time frame
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    {showProviders && (
                        <Grid item xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key={allText} value={allText}>
                                        {allText}
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Appointment Types</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Appointment Types"
                                value={appointmentTypes}
                                multiple
                                onChange={handleOnChange}
                                onClose={() => (isTypeUpdated ? changeFilter() : null)}
                                input={<OutlinedInput label="Appointment Types" />}
                                renderValue={(selected) => (isSelectAll ? allText : selected.join(', '))}
                            >
                                <MenuItem value={allText} className={isSelectAll ? 'Mui-selected' : ''} sx={{ padding: '0', paddingLeft: '10px' }}>
                                    <Checkbox checked={isSelectAll} indeterminate={Boolean(appointmentTypes?.length) && !isSelectAll} />
                                    {allText}
                                </MenuItem>
                                {appointmentTypeList.map((type) => {
                                    return (
                                        <MenuItem key={type.value} value={type.text} sx={{ padding: '0', paddingLeft: '10px' }}>
                                            <Checkbox checked={appointmentTypes.indexOf(type.text) > -1} />
                                            <ListItemText primary={type.text} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Time Filter</InputLabel>
                            <Select value={timeFilter} label="Time Filter" name="Time" onChange={(e) => setTimeFilter(parseInt(e.target.value as any))}>
                                {timePeriods.map((date) => (
                                    <MenuItem key={date.value} value={date.value}>
                                        {date.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} marginTop={2} position="relative">
                    {isLoading && <WidgetLoading />}
                    <div style={{ width: '100%', height: '51vh' }}>
                        <DataGrid
                            rows={reschedulingOpportunities}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => Math.random()}
                            getRowHeight={() => 'auto'}
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                height: '51vh',
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ReschedulingOpportunities;
